module.exports = {
  siteMetadata: {
    title: `Shopsmart`,
    description: `Shopsmart Loyalty Card and E-Wallet. With Shopsmart you can easily store and manage all your cards in one app and browse the latest offers from your favourite retailers and brands`,
    author: `Sean Kerr`,
    siteUrl: `https://www.shopsmart.com.au`,
    image: `https://www.shopsmart.com.au/static/Shopsmart3(S)-14b1290fc77f61f8277a4036d0090bb2.jpg`,
    url: `https://www.shopsmart.com.au`,
    type: `website`,
    keywords: `deals, shopping, shopping list, shopping centre near me, online, bargain hunt, loyalty, loyalty card, loyalty programs, loyalty card app australia, loyalty cards app`,
    ahrefs: `dc81e744fc1751c07164efce0964adc4f15ef95df2bc8dd064dca395d2aefa11`,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "UA-109269993-1", // Google Analytics / GA
        ],
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: false,
          // Setting this parameter is also optional
          respectDNT: false,
          // Avoids sending pageview hits from custom paths
          // exclude: ["/preview/**", "/do-not-track/me/too/"],
        },
      },
    },
    {
      resolve: `gatsby-plugin-env-variables`,
      options: {
        allowList: ["APIURL", "LOCALURL", "HOST"],
      },
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://www.shopsmart.com.au`,
      },
    },
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName: `${process.env.AWS_BUCKET_NAME}`,
      },
    },
    // `gatsby-plugin-sitemap`,
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        excludes: ["/home"],
      },
    },
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Shopsmart.com.au`,
        short_name: `Shopsmart`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/gatsby-icon.png`, // This path is relative to the root of the site.
      },
    },
    `gatsby-plugin-gatsby-cloud`,
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "uaf2GsySBBO9KsEn0HzmTgtt",
        version: "draft",
        // languages: ['de', 'at'] // Optional parameter. Omission will retrieve all languages by default.
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
