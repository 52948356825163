import * as React from "react"
import { Link } from "gatsby"
import { Fragment } from "react"
import { Location } from "@reach/router"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { SearchIcon } from "@heroicons/react/solid"
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline"
import Logo from "../images/Shopsmart3(S).jpg"

import { useLocation } from "@reach/router"

const navigation = [
  { name: "Blog", href: "/shopsmart-blog", current: false },
  { name: "App", href: "/app", current: false },
  { name: "Contact", href: "/contact", current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function setLink(item, loc) {
  if (loc.pathname === item.href) {
    item.current = true
  } else {
    if (loc.pathname.indexOf("articles") === 1 && item.href.includes("blog")) {
      item.current = true
    } else {
      item.current = false
    }
  }
}

const Header = () => {
  const location = useLocation()

  return (
    <Disclosure as="nav" className="bg-purple-dark">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <a href={`/`} className="block mt-2">
                    <img
                      className="block h-14 w-auto"
                      src={Logo}
                      alt="Shopsmart logo"
                    />
                  </a>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4 mt-4">
                    {navigation.map(
                      item => (
                        setLink(item, location),
                        (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-700 text-white"
                                : "no-underline text-gray-300 hover:bg-gray-500 hover:text-white border-2 border-light-blue-500 border-opacity-25",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
export default Header
